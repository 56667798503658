import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-usuarios-hora-actividad',
  templateUrl: './dashboard-usuarios-hora-actividad.component.html',
  styleUrls: ['./dashboard-usuarios-hora-actividad.component.scss']
})
export class DashboardUsuariosHoraActividadComponent implements OnInit {

  @Input() semana = ''
  @Input() heatMapData = []



  colorScheme = {
    domain:
    ['#af4ed1', '#c68ce1', '#ddbaf1', '#8a3eb1', '#693181']
  };


  daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  


  make_filter() {
    
    console.log(this.semana)

  }

  constructor() {

  }

  ngOnInit() {

  }



}

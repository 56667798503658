<div class="row">
    <h3>Cantidad leads</h3>
    <div class="col-12 col-sm-12 col-xl-12 col-lg-12">
        <div class="card o-hidden">
            <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                    
                    <div class="media-body"><span class="m-0">Atendidos</span>
                        <h4 class="mb-0 counter">{{data.atendidos}} ({{data.porcentaje_atendidos}}%)</h4><i
                            class="icon-bg" data-feather="percent"></i>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})


export class ContactCenterService {

  private list_of_campus
  constructor(private http: HttpClient) { }



  async contact_center_tiendas() {

    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ContactCenterAPI/traer_tiendas`, data))

    return response;

  }

  async exportar(to_send) {

    const data = new FormData()
    data.append('data', JSON.stringify(to_send))

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ContactCenterAPI/export_contact_center`, data))

    return response

  }

  async contact_center_init(campus_id: Number) {

    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('campus_id', `${campus_id}`)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ContactCenterAPI/get_payload_init`, data))

    return response

  }

}

<!-- <div class="row">
    <h3>Tiempos promedios</h3>
    <div class="col-12 col-sm-12 col-xl-4 col-lg-4">
        <div class="card o-hidden">
            <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                    
                    <div class="media-body"><span class="m-0">Primera apertura</span>
                        <h4 class="mb-0 counter">{{data.tiempo_promedio_primera_apertura}}</h4><i class="icon-bg" data-feather="clock"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-xl-4 col-lg-4">
        <div class="card o-hidden">
            <div class="bg-secondary b-r-4 card-body">
                <div class="media static-top-widget">
                    
                    <div class="media-body"><span class="m-0">Primera atención</span>
                        <h4 class="mb-0 counter">{{data.tiempo_promedio_primera_edicion}}</h4><i class="icon-bg" data-feather="clock"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-xl-4 col-lg-4">
        <div class="card o-hidden">
            <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                    
                    <div class="media-body"><span class="m-0">Duración de llamada</span>
                        <h4 class="mb-0 counter">{{data.tiempo_promedio_llamada}}</h4><i class="icon-bg" data-feather="clock"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="row">
    <h3>Tiempos promedios</h3>
    <div class="col-12 col-sm-12 col-xl-4 col-lg-4">
        <div class="card o-hidden">
            <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                    
                    <div class="media-body"><span class="m-0">Primera apertura</span>
                        <h4 class="mb-0 counter">2:15 minutos</h4><i class="icon-bg" data-feather="clock"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-xl-4 col-lg-4">
        <div class="card o-hidden">
            <div class="bg-secondary b-r-4 card-body">
                <div class="media static-top-widget">
                    
                    <div class="media-body"><span class="m-0">Primera atención</span>
                        <h4 class="mb-0 counter">5:45 minutos</h4><i class="icon-bg" data-feather="clock"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-xl-4 col-lg-4">
        <div class="card o-hidden">
            <div class="bg-primary b-r-4 card-body">
                <div class="media static-top-widget">
                    
                    <div class="media-body"><span class="m-0">Duración de llamada</span>
                        <h4 class="mb-0 counter">3:30 minutos</h4><i class="icon-bg" data-feather="clock"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="form-group">
        <h2>Agregar concesionario</h2>
    </div>
    <div class="form-group">
        <label for="name">Ingrese Nombre del Parque</label>
        <input id="name" class="form-control" type="text" placeholder="" [(ngModel)]="data.name">
    </div>
    <div class="form-group">
        <label for="departamento">Elija departamento</label>
        <ng-select id="departamento" placeholder="departamento" (change)="buscar_provincia($event)" class="mt-2"
            [items]="departamentos" bindLabel="name" bindValue="id" [(ngModel)]="data.departamento"></ng-select>
    </div>

    <div class="form-group">
        <label for="provincia">Elija provincia</label>
        <ng-select id="provincia" placeholder="provincia" (change)="buscar_distritos($event)" class="mt-2"
            [items]="provincias" bindLabel="name" bindValue="id" [(ngModel)]="data.provincia"></ng-select>
    </div>

    <div class="form-group">
        <label for="distrito">Elija distrito</label>
        <ng-select id="distrito" placeholder="distrito" class="mt-2" [items]="distritos" bindLabel="name" bindValue="id"
            [(ngModel)]="data.distrito"></ng-select>
    </div>


    <div class="form-group">
        <label for="region">Elija region</label>
        <ng-select id="region" class="mt-2" [items]="regiones" bindLabel="name" bindValue="id"
            [(ngModel)]="data.region"></ng-select>
    </div>
    <div class="form-group">
        <div class="media">
            <label class="col-form-label m-r-10">¿Big Bigkes?</label>
            <div class="media-body text-end">
                <label class="switch">
                    <input [(ngModel)]="data.big_bike" type="checkbox" checked=""><span class="switch-state"></span>
                </label>
            </div>
        </div>
    </div>


    <div class="form-group buttons">
        <button class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs'
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { CampusService } from 'src/app/shared/services/campus.service';
@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private campusService: CampusService) { }

  async ngOnInit() {
    console.log('entro a leads')
    const result = <any>await this.generateView()
    
    if (Array.isArray(result)) {
      this.router.navigate(['leads/tienda'])
    } else {
      console.log(result)
      if (result) {
        this.router.navigate([result])
      }else{
        this.router.navigate([''])
      }

    }
  }

  async generateView() {
    try {
      console.log('generateview')
      return await this.campusService.get_list_of_campus()
    } catch (error) {
      console.log('Error :',error.message)
    }
  }

}

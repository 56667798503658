import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ChatUsers } from '../../shared/model/chat.model';
import { ChatService } from '../../shared/services/chat.service';
import { environment } from '../../../environments/environment';
import { Location } from "@angular/common";
import { SocketService } from '../../shared/services/socket.service';





const Swal = require('sweetalert2')

@Component({
  selector: 'app-lead-whatsapp',
  templateUrl: './lead-whatsapp.component.html',
  styleUrls: ['./lead-whatsapp.component.scss'],
  
})
export class LeadWhatsappComponent implements OnInit, AfterViewInit, OnDestroy {

  private routeSub: Subscription;
  private tienda_id
  private lead_id
  private last_whatsapp_response

  public disponible_mandarwhatsapp = false
  message: string;
  lead


  subscription !: Subscription


  public openTab: string = "call";
  public users: ChatUsers[] = []
  public searchUsers: ChatUsers[] = []
  public chatUser: any;
  public profile: any;
  public chats: any;
  public chatText: string;
  public error: boolean = false;
  public notFound: boolean = false;
  public id: any;
  public searchText: string;
  public showEmojiPicker: boolean = false;
  public emojies: any;
  public mobileToggle: boolean = false

  constructor(
    private route: ActivatedRoute,
    private chatService: ChatService,
    private http: HttpClient,
    private location: Location,
    private socketService: SocketService 
  ) {
    


    this.routeSub = this.route.params.subscribe(parametros => {
      this.tienda_id = parametros.tienda_id
      this.lead_id = parametros.lead_id
    })

    this.chatService.getUsers().subscribe(users => {
      this.searchUsers = users
      this.users = users
    })


     // this.refresh();

    setInterval(() => {
      console.log('consulta');
      this.refresh();
    }, 5000); 


     this.actualizar_comunicacion();
   
  }


  actualizar_comunicacion(){

    this.socketService.sendMessage({
      lead_id: this.lead_id,
      tienda_id: this.tienda_id,
      comunicacion_type: `Whatsapp`,
      jwt: JSON.parse(localStorage.user).jwt
    })
    
    this.socketService.getComunicacion();

  }  



  async ngAfterViewInit() {


    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Whatsapp`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_lead_detalle_by_id`, data))
    this.lead = response.lead

    this.disponible_mandarwhatsapp = this.lead.disponible_mandarwhatsapp

    // console.log(this.lead)
    this.userChat(this.id)
    this.getProfile()

  }



  async ngOnInit() {



  }


  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  public toggleEmojiPicker() {

    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    const text = `${event.emoji.native}`;
    this.chatText = text;
    this.showEmojiPicker = false;
  }

  public tabbed(val) {
    this.openTab = val
  }

  // Get user Profile
  public getProfile() {
    this.chatService.getCurrentUser().subscribe(userProfile => this.profile = userProfile)
  }

  // User Chat
  async userChat(id: number = 1) {
    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Whatsapp`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const whatsapp_response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_historial_comunicaciones_by_type`, data))
    this.chatService.chatToUser(id).subscribe(chatUser => this.chatUser = chatUser)
    this.chatService.getChatHistory(id, whatsapp_response.historial).subscribe(chats => this.chats = chats)
    // console.log('chats :> ', this.chats)
  }

  // Send Message to User

  async iniciarConversacion(form) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Comenzarás/retomarás la conversación con el cliente",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#000',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.startConversation()
      }
    })
  }

  async startConversation() {

    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Whatsapp`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/whatsapp_start_conversation`, data))
    // console.log('startConversation : response => ', response)
  }

  async sendMessage(form) {

    if (!form.value.message) {
      this.error = true
      return false
    }

    this.error = false
    let chat = {
      sender: this.profile.id,
      receiver: this.chatUser.id,
      receiver_name: this.chatUser.name,
      message: form.value.message
    }



    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Whatsapp`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    data.append('chat', JSON.stringify(chat))


    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/insert_comunicacion`, data))
    // console.log('response', response)
    if (response.success) {
      this.chatService.sendMessage(chat)
      this.chatText = ''
      this.chatUser.seen = 'online'
      this.chatUser.online = true
    } else {
      Swal.fire(
        response.message,
        '',
        'error'
      )
    }
  }

  searchTerm(term: any) {
    if (!term) return this.searchUsers = this.users
    term = term.toLowerCase();
    let user = []
    this.users.filter(users => {
      if (users.name.toLowerCase().includes(term)) {
        user.push(users)
      }
    })
    this.searchUsers = user
  }

  mobileMenu() {
    this.mobileToggle = !this.mobileToggle;
  }

  async refresh() {

    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Whatsapp`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const whatsapp_response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_historial_comunicaciones_by_type`, data))
    const cantidad_mensajes = whatsapp_response.historial[0].message.length
    if (this.last_whatsapp_response != cantidad_mensajes) {
      this.last_whatsapp_response = cantidad_mensajes
      this.disponible_mandarwhatsapp = whatsapp_response.whatsapp
      this.chatService.chatToUser(1).subscribe(chatUser => this.chatUser = chatUser)
      this.chatService.getChatHistory(1, whatsapp_response.historial).subscribe(chats => this.chats = chats)
    }

  }


  go_back() {
    console.log(this.location);

    window.location.href = `/leads/tienda/${this.tienda_id}/${this.lead_id}/`;

  // this.location.back()
  // lead_id: this.lead_id,
  // tienda_id: this.tienda_id,
    
  }

}

import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CampusService } from 'src/app/shared/services/campus.service';
import { NavidadService } from 'src/app/shared/services/navidad.service';
import { lastValueFrom } from 'rxjs'

import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { ContentObserver } from '@angular/cdk/observers';
import { ModalReasignarmasivoLeadComponent } from 'src/app/modal-reasignarmasivo-lead/modal-reasignarmasivo-lead.component';

const Swal = require('sweetalert2')


// import { Person } from '';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  detallado_leads: any;
}

@Component({
  selector: 'app-campana-navidad',
  templateUrl: './campana-navidad.component.html',
  styleUrls: ['./campana-navidad.component.scss']
})
export class CampanaNavidadComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;


  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<void> = new Subject();

  spanish_datatable

  tableCabeceras: any
  dataTablesParameters: any
  persons: []

  private routeSub: Subscription;
  tienda_seleccionada
  nombre_tienda_seleccionada
  detallado_leads
  tiendas: [];
  isCollapsed
  default_values

  user_type_id
  num_of_cols

  // ARRAY CON FILTROS
  filtros


  buttonFiltrarTexto

  campus_name = ''


  leads_selected = []


  public multipleSelectedCity: string[];


  message_to_show_table = '¡Cargando!'






  constructor(private campus: CampusService,
    private route: ActivatedRoute,
    private leadService: NavidadService,
    private http: HttpClient,
    private modalService: NgbModal,
  ) {
    this.user_type_id = JSON.parse(localStorage.user).user_type_id


      this.tableCabeceras = [
        {
          'id': 0,
          'name': 'Num.'
        },
        {
          'id': 1,
          'name': 'Nombre Tutor'
        },
        {
          'id': 2,
          'name': 'DNI'
        },
        {
          'id': 3,
          'name': 'Fecha Lead'
        },
        {
          'id': 4,
          'name': 'Categoría elegida del regalo'
        },
        {
          'id': 5,
          'name': 'Estado Regalo'
        },
        
        {
          'id': 6,
          'name': 'Ver Detalle'
        }
      ]

   


    this.num_of_cols = 4
  
    this.persons = []
    this.filtros = {}
    if (localStorage.getItem("lead_comercial__filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("lead_comercial__filtros"))
    }

    this.filtros.jwt = JSON.parse(localStorage.user).jwt
    this.filtros.user_type_id = JSON.parse(localStorage.user).user_type_id

    this.detallado_leads = {
      total: 0
    }

    this.default_values = {}
    this.isCollapsed = true

    this.spanish_datatable = {
      processing: "Procesando...",
      search: "Buscar:",
      lengthMenu: "Mostrar _MENU_ elementos",
      info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
      infoEmpty: "Mostrando ningún elemento.",
      infoFiltered: "(filtrado _MAX_ elementos total)",
      infoPostFix: "",
      loadingRecords: "Cargando registros...",
      zeroRecords: "",
      // zeroRecords: "No se encontraron registros",
      emptyTable: "No hay datos disponibles en la tabla",
      paginate: {
        first: "Primero",
        previous: "Anterior",
        next: "Siguiente",
        last: "Último"
      },
      aria: {
        sortAscending: ": Activar para ordenar la tabla en orden ascendente",
        sortDescending: ": Activar para ordenar la tabla en orden descendente"
      }
    }

    this.buttonFiltrarTexto = `Filtrar`

  }

  formatearEstadoCliente(estadoCliente: string): string {
    // Convertir a minúsculas y eliminar espacios en blanco
    return estadoCliente.toLowerCase().replace(/\s/g, '');
  }


  async exportar() {
    const data = new FormData()
    data.append('filtro', this.filtros)

    var enlace = `${environment.url_api}NavidadAPI/exportar_registros_navidad`;

    const response = <any>await lastValueFrom(this.http.post(enlace, data))

    if (response.success) {
      try {
          const response = <any>await lastValueFrom(this.http.post(enlace, data));

          if (response.success) {
              // Abre una nueva pestaña con el enlace proporcionado
              window.open(response.ruta, '_blank');
          } else {
              console.error('Error al iniciar la descarga:', response.message);
          }
      } catch (error) {
          console.error('Hubo un error con la solicitud:', error);
      }
      
    }
  }


  showTabla() {
    const that = this;

    const to_send = new FormData()

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      serverSide: true,
      processing: true,
      searching: false,
      order: [0, 'DESC'],
      columnDefs: [
        { "orderable": false, "targets": [this.num_of_cols] }
      ],
      language: this.spanish_datatable,
      ajax: (dataTablesParameters, callback) => {
        const info = Object.assign(dataTablesParameters,
          this.filtros
        )

        to_send.append('data', JSON.stringify(info))


        that.http
          .post<DataTablesResponse>(
            `${environment.url_api}NavidadAPI/get_registros_navidad`,
            to_send
          ).subscribe(resp => {
            resp.data.map(el => {
              if (this.leads_selected.find(element => element == el.id)) {
                el.checked = true
              } else {
                el.checked = false
              }
            })
            console.log(resp.data)
            that.persons = <any>resp.data
            const detalle = <any>resp.detallado_leads

            

            this.detallado_leads.total = Number.parseInt(detalle.recordsTotal) ;


            console.log("FILTRO => ", this.filtros)
            console.log("FILTRO => ", JSON.stringify(this.filtros))
            localStorage.setItem("lead_comercial__filtros", JSON.stringify(this.filtros))
            this.buttonFiltrarTexto = "Filtrar"



            if (resp.recordsTotal > 0) {
              this.message_to_show_table = '¡Cargando!'
            } else {
              this.message_to_show_table = 'No se encontraron resultados'
            }

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: this.detallado_leads.total,
              data: []
            });
          });
      },
      // columns: [{ data: 'id' }, { data: 'firstName' }, { data: 'lastName' }]
    };
  }

  async ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' })


    this.tienda_seleccionada = -1
    this.routeSub = this.route.params.subscribe(params => {
      this.tienda_seleccionada = params['tienda_id']
    })

      this.showTabla()
      const res = <any>await this.leadService.lead_service_init()


      if (res.success) {
        if (res.payload) {
          if (res.payload.campus) {
            this.campus_name = res.payload.campus.name
          }
        }
      }


      this.default_values.respondio_llamada = res.payload.respondio_llamada
      this.default_values.estado_cliente = res.payload.estado_cliente
      this.default_values.tipo_filtro = res.payload.tipo_filtro
      this.default_values.modelo = res.payload.modelo
      this.default_values.plataforma = res.payload.plataforma
      this.default_values.fuente = res.payload.fuente
      this.default_values.region = res.payload.region
      this.default_values.utm_source = res.payload.utm_source
      this.default_values.utm_medium = res.payload.utm_medium
      this.default_values.utm_campaign = res.payload.utm_campaign
      this.default_values.utm_term = res.payload.utm_term
      this.default_values.financiamiento = false

      this.default_values.vendedores = res.payload.sellers_disponibles



      console.log('default_values', this.default_values)

      this.nombre_tienda_seleccionada = ''
      if (res.payload.campus) {
        this.nombre_tienda_seleccionada = `${res.payload.campus.name} - ${res.payload.campus.distrito_name}`
      }





  }
  rerender(): void {

    this.buttonFiltrarTexto = `Cargando...`
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }


  reasignar() {

    if (this.leads_selected.length > 1) {
      const dialogRef = this.modalService.open(ModalReasignarmasivoLeadComponent,
        {
          size: 'lg'
        })
      dialogRef.componentInstance.leads_selected = this.leads_selected
      dialogRef.componentInstance.jwt = JSON.parse(localStorage.user).jwt
      dialogRef.componentInstance.tienda_seleccionada = this.tienda_seleccionada
      dialogRef.result.then((data) => {
        if (data.success) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
          this.leads_selected = []
        }
      })
    } else {
      Swal.fire('Para usar esta funcionalidad, debes seleccionar más de un lead', '', 'warning')
    }
  }

  select_lead($event) {
    const id = $event.target.value
    if ($event.target.checked) {
      this.leads_selected.push(id)
    } else {
      this.leads_selected = this.leads_selected.filter(function (e) {
        return e !== id
      })

    }


  }


}



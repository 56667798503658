<app-breadcrumb [title]="'Detalle Registro'" [items]="['Registros']" [active_item]="'Detalle Registro'"></app-breadcrumb>
<div class="go-back">
    <a (click)="go_back()"><i class="fa fa-arrow-circle-left"></i></a>
</div>
<div class="container-fluid">
    <div class="user-profile">
        <div class="row">
            <!-- user profile first-style start-->
            <div class="col-12 col-sm-12 col-md-12 col-xl-7 col-lg-12">
                <div class="card ribbon-wrapper hovercard text-center">
                    <div class="ribbon ribbon-primary bg-{{formatearEstadoCliente(lead.estado_regalo)}}">{{lead.estado_regalo}}</div>

                    <div class="info">
                        <div class="row">
                      
            
                            <!-- Datos del Niño -->
                            <div class="col-sm-12 col-lg-12 order-sm-0 order-lg-0 order-xl-0">

                                <div class="user-designation">
                                    <div class="title"><a>{{lead.nombre_infante}}</a></div>
                                 
                                </div>
                                <!-- Foto de la Boleta -->
                                <div class="col-sm-12 col-lg-12 order-sm-2 order-lg-2 order-xl-2">
                                    <div class="ttl-info text-center lead-info">
                                        <h6><i class="fa fa-camera"></i> Foto de la Boleta</h6>
                                        <div *ngIf="lead.boleta_imagen">
                                            <img 
                                                width="300px"
                                                [src]="lead.boleta_imagen" 
                                                alt="Foto de la boleta" 
                                                class="img-fluid" 
                                                (click)="showImage(lead.boleta_imagen)"
                                                style="cursor: pointer;">
                                        </div>
                                        <div *ngIf="!lead.boleta_imagen">
                                            <span>No hay imagen disponible.</span>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                            <!-- Datos del Tutor -->
                            <div class="col-sm-12 col-lg-12 order-sm-1 order-lg-1 order-xl-1">
                                <div class="ttl-info text-center lead-info">
                                    <h6><i class="fa fa-user"></i> Datos Infante</h6>
                                    <span title="{{lead.nombre}} {{lead.apellido}}" class="lead-info__text"><b>Nombre:</b> {{lead.nombre_infante}} </span><br>
                                    <span title="{{lead.edad_infante}} años" class="lead-info__text"><b>Edad:</b> {{lead.edad_infante}} años</span><br>
                                </div>
                            </div>
            
                            <div class="col-sm-12 col-lg-12 order-sm-1 order-lg-1 order-xl-2">
                                <div class="ttl-info text-center lead-info">
                                    <h6><i class="fa fa-user"></i> Datos del Tutor</h6>
                                    <span title="{{lead.nombre_tutor}}" class="lead-info__text"><b>Nombre:</b> {{lead.nombre}} {{lead.apellido}} </span><br>
                                    <span title="{{lead.telefono_tutor}}" class="lead-info__text"><b>Teléfono:</b> {{lead.celular}}</span><br>
                                    <span title="{{lead.correo_tutor}}" class="lead-info__text"><b>Email:</b> {{lead.correo_electronico}}</span>
                                </div>
                            </div>
                            
                        </div>
            
             
            
                        <!-- Acciones -->
                        <!--
                            <div class="acciones_asesor" >
                                <hr>
                                <div class="contactarse">
                                    <div class="row">

                                        <div class="col-12 col-sm-6 opciones" *ngIf="telefono">
                                            <span>
                                                <button [innerHTML]="texto_button_call" (click)="make_call()" class="btn"
                                                    [ngClass]="{'btn-info':!on_call == true, 'btn-danger':on_call == true}"></button>
                                            </span>
                                        </div>

                                        <div class="col-12 col-sm-6 opciones" *ngIf="telefono">
                                            <span>
                                                <a [routerLink]="'whatsapp'" class="btn btn-success">Whatsapp<br><i
                                                        class="icofont icofont-brand-whatsapp"></i></a>
                                            </span>
                                        </div>
                                        <div class="col-12 col-sm-6 opciones" *ngIf="sendMail">
                                            <span>
                                                <a [routerLink]="'mail'" class="btn btn-secondary">Email <i
                                                        class="icofont icofont-send-mail"></i></a>
                                            </span>
                                        </div>
                                        <div class="col-12 col-sm-6 opciones" *ngIf="sendMail">
                                            <span>
                                                <button (click)="send_cotizacion()" class="btn btn-warning">
                                                    Enviar cotización
                                                    <i class="icofont icofont-file-pdf"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <div class="col-12 opciones">
                                            <span>
                                                <a [routerLink]="'history'" class="btn btn-danger">Historial de comunicación<br><i
                                                class="icofont icofont-history"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        -->


                        <div class="col-12  m-b-15" *ngIf="lead.utms">
                            <hr>

                            <h4><u>UTMS:</u></h4>
                            <div class="row container_utms">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                    <h6>UTM SOURCE</h6>
                            
                                    <ng-container *ngIf="lead.utms.utm_source == 'google'; then thenGoogle"></ng-container>
                                    <ng-container *ngIf="lead.utms.utm_source == 'facebook'; then thenFacebook"></ng-container>
                                    <ng-container *ngIf="lead.utms.utm_source == 'instagram'; then thenInstagram"></ng-container>
                                    
                                    <ng-container *ngIf="lead.utms.utm_source !== 'instagram' && lead.utms.utm_source !== 'google' && lead.utms.utm_source !== 'facebook'; then thenDefault"></ng-container>
                                    

                                    <label for="">
                                        <ng-template #thenGoogle>
                                            <div class="p-b-15 icon_utm">
                                                <i class="fa fa-google display-3 text-danger opacity-05" aria-hidden="true"></i>
                                            </div>
                                        </ng-template>

                                        <ng-template #thenFacebook>
                                            <div class="p-b-15 icon_utm">
                                                <i class="fa fa-facebook display-3 text-danger opacity-05" aria-hidden="true"></i>
                                            </div>
                                        </ng-template>

                                        <ng-template #thenInstagram>
                                            <div class="p-b-15 icon_utm">
                                                <i class="fa fa-instagram display-3 text-danger opacity-05" aria-hidden="true"></i>
                                            </div>
                                        </ng-template>

                                        <ng-template #thenDefault>
                                            <div class="p-b-15 icon_utm">
                                                <i class="icofont icofont-speech-comments display-3 text-danger opacity-05" aria-hidden="true"></i>
                                            </div>
                                        </ng-template>

                                                                                   
                                        <span class="badge rounded-pill badge-primary">  {{lead.utms.utm_source | uppercase}} </span>

                                    </label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                    <h6>UTM MEDIUM</h6>
                                    <div class="p-b-15 icon_utm">
                                        <i class="icofont icofont-comment display-3 text-dark opacity-05"></i>
                                    </div>
                                    <label for="">
                                        <span
                                            class="badge rounded-pill badge-secondary"> {{lead.utms.utm_medium | uppercase}} </span>
                                        </label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                    <h6>UTM CAMPAIGN</h6>
                                    <div class="p-b-15 icon_utm">
                                        <i class="icofont icofont-anchor display-3 text-warning opacity-05"></i>
                                    </div>
                                    <label for=""><span
                                            class="badge rounded-pill badge-warning"> {{lead.utms.utm_campaign | uppercase}} </span></label>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                    <h6>UTM TERM</h6>
                                    <div class="p-b-15 icon_utm">
                                        <i class="fa fa-align-left display-3 text-success opacity-05" aria-hidden="true"></i>
                                    </div>
                                    <label for=""><span
                                            class="badge rounded-pill badge-success"> {{lead.utms.utm_content | uppercase}} </span></label>
                                </div>

                      
                                <hr>
                                   
                                <div class="form-group" *ngIf="user_type_id != 2">
                                    <label for="primera_apertura"><u>Tiempo de primera apertura:</u></label>
                                    <p>{{tiempos.primera_apertura ? tiempos.primera_apertura : '-' }}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 2">
                                    <label for="primera_atencion"><u>Tiempo de primera atención:</u></label>
                                  
                                    <p>{{tiempos.primera_atencion ? tiempos.primera_atencion : '-'}}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 2">
                                    <label for="ultimo_click"><u>Último click:</u></label>
                           
                                    <p>{{tiempos.ultimo_click ? tiempos.ultimo_click : '-'}}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 2">
                                    <label for="last_edit"><u>Última edición:</u></label>
                                  
                                    <p>{{tiempos.last_edit ? tiempos.last_edit : '-'}}</p>
                                </div>
                                

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            

            <div class="col-12 col-sm-12 col-md-12 col-xl-5 col-lg-12 box-col-5">
                <div class="card info-right">
                    <div class="card-header py-4">
                        <h5>Información</h5>
                    </div>
                    <div class="card-body">
                        <div class="theme-form row">
                            <div class="formulario-edit">
                        
                           
                                <div class="row mt-2 mb-2">
                                    <div  class="col-12 col-md-12">
                                        <div class="ttl-info text-center lead-info">
                                            <label for="seguimiento_caso">¿Cómo te has portado?</label>
                                            <input class="form-control mt-2" type="text" [value]="lead.como_te_portaste" disabled style="text-transform: uppercase;">
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="ttl-info text-center lead-info">
                                            <label for="seguimiento_caso"><i class="fa fa-gift"></i> Categoría del Regalo</label>
                                            <input class="form-control mt-2" type="text" [value]="lead.categoria_regalo" disabled>

                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="ttl-info text-center lead-info">
                                            <label for="comentarios"><i class="fa fa-heart"></i> El Regalo Elegido </label>
                                            <textarea [disabled] [(ngModel)]="lead.regalo_elegido"
                                                class="form-control" name="" id="comentarios_cliente" cols="30" rows="5" disabled ></textarea>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-12">
                                    <div class="ttl-info text-center lead-info">
                                        <label for="seguimiento_caso">Tienda</label>
                                        <input class="form-control mt-2" type="text" [value]="lead.tienda" disabled>

                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="ttl-info text-center lead-info">
                                        <label for="seguimiento_caso">Distrito</label>
                                        <input class="form-control mt-2" type="text" [value]="lead.distrito" disabled>

                                    </div>
                                </div>
                                
                                <div class="col-12">
                                    <div class="form-group col-12">
                                        <label class="col-form-label">¿Respondió llamada?</label>
                                        <ng-select placeholder="" [items]="payloadForm.respondio_llamada" 
                                            class="js-example-basic-multiple"
                                            [(ngModel)]="lead.respondio_llamada">
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="form-group col-12">
                                    <label for="">Estado Regalo</label>
                                    <ng-select [items]="payloadForm.estado_regalo"
                                        [(ngModel)]="lead_to_update.estado_regalo" class="js-example-basic-multiple"
                                        >
                                    </ng-select>
                                </div>


                                
                        
                         

                                <div class="form-group col-12" >
                                    <label for="notas">Crear Nueva Nota <i class="fa fa-plus-circle" aria-hidden="true"></i></label>
                                    <textarea class="form-control" name="" id="comentarios" cols="30" rows="5" [(ngModel)]="nueva_nota"></textarea>
                                </div>

                                <div class="form-group row col-12 m-b-15"  >
                                    <div class="col-6">

                                        <button class="form-group btn btn-success col-12" (click)="guardarNota()" [disabled]="!nueva_nota.trim()">
                                            <i class="icofont icofont-comment"></i> Guardar Nota
                                        </button>
                                    </div>
                                    <div class="col-6" >
                                        <button class="btn btn-danger col-12" (click)="actualizar()">
                                            Guardar Cambios
                                        </button>
                                    </div>

                                    <div class="col-12">
                                        <button class="form-group btn btn-info col-12" (click)="toggleCollapse()">
                                            <i class="icofont icofont-speech-comments"></i>
                                            {{ isCollapsed ? 'Mostrar Notas' : 'Ocultar Notas' }}
                                        </button>
                                    </div>

                        
                           
                                </div>

                               
                                

                                <div id="comentariosCollapse" class="m-b-15" *ngIf="comentarios_lead && comentarios_lead.length > 0">

                                    <h4 *ngIf="!isCollapsed" >Comentarios:</h4>
                                    
                                    <div class="card card-body" [ngClass]="{'collapse': isCollapsed}" >
                                  

                                        <!-- Contenido del comentario -->
                                        <div *ngFor="let comentario of comentarios_lead" [ngClass]="'mt-2 border rounded p-3 bg-' + formatearEstadoCliente(comentario.estado_cliente)" >

                                            <div class="border-bottom mb-3">
                                                <p class="font-weight-bold mb-1">Estado del Registro: {{comentario.estado_cliente}}</p>
                                            </div>

                                            <p class="mb-2" style="font-size: 11px;">{{comentario.accion}}</p>
                                            <p class="mb-2" style="font-size: 14px;">{{comentario.comentario}}</p>
                                            <p class="mb-0 " style="font-size: 10px;">{{comentario.created_time}}</p>
                                        </div>
                                    </div>
                                </div>



<!-- 
                                <div class="form-group button-options col-12 m-b-15" *ngIf="user_type_id == 2">
                                    <button class="btn btn-primary" (click)="actualizar()">Guardar</button>
                                </div>
-->

                            </div>

                   
                        
                                
                            
                            <!-- <div class="col-12">
                                <button class="btn btn-primary d-block w-100" type="button" title="">Submit</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common"
import { Subscription, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

const Swal = require('sweetalert2')

@Component({
  selector: 'app-lead-mail',
  templateUrl: './lead-mail.component.html',
  styleUrls: ['./lead-mail.component.scss']
})
export class LeadMailComponent implements OnInit, AfterViewInit {
  private routeSub: Subscription;
  private tienda_id
  private lead_id

  payloadForm
  user_type_id
  lead
  buttonEnviarText

  history
  fuente = "Lead";

  constructor(private route: ActivatedRoute, private http: HttpClient,
    private router: Router,
    private location: Location) {
    this.lead = []
    this.user_type_id = JSON.parse(localStorage.user).user_type_id
    this.routeSub = this.route.params.subscribe(parametros => {

      const currentPath = this.route.snapshot.routeConfig?.path;

      if (currentPath === 'campana-navidad/:lead_id/mail') {
        this.fuente = "Registro Navidad";
      }

      this.tienda_id = parametros.tienda_id
      this.lead_id = parametros.lead_id
    })
    this.payloadForm = {
      mailing_asunto: '',
      message: ''
    }

    this.buttonEnviarText = `Enviar`

    this.history = []


  }

  async sendMail() {
    this.buttonEnviarText = `Enviando...`
    if (this.validateEmail()) {
      Swal.fire({
        title: '¿Deseas enviar el correo?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, enviar',
        cancelButtonText: 'No',

      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = new FormData()
          data.append('fuente', this.fuente)
          data.append('lead_id', this.lead_id)
          data.append('tienda_id', this.tienda_id)
          data.append('comunicacion_type', `Email`)
          data.append('jwt', JSON.parse(localStorage.user).jwt)
          data.append('payload', JSON.stringify(this.payloadForm))

          console.log(data)

          const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/send_mailing_single`, data))
          if (response.success) {
            this.sendAlert(response.message, 'success')
            this.payloadForm.mailing_asunto = ``
            this.payloadForm.message = ``
            this.history = response.historial
          } else {
            this.sendAlert(response.message, 'error')
          }
        } else {
          console.log('false')
        }
      })

    } else {
      console.log('no')
    }

    this.buttonEnviarText = `Enviar`

  }

  async verDetalle(id) {
    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Email`)
    data.append('comunicacion_id', id)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    Swal.showLoading()
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_detalle_by_comunicacion_id`, data))
    Swal.close()
    console.log(response)

    var html_historial = ``
    console.log('este es response:', response)
    if (response.historial.length > 0) {

      response.historial.forEach(element => {
        html_historial += `Evento : ${element.evento} - Fecha : ${element.fecha}<br>`
      });
    } else {
      html_historial += `No se encontró historial`
    }



    Swal.fire({
      title: '<strong>Detalle de la comunicación</strong>',
      icon: 'info',
      html: html_historial,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        'Cerrar',
    })
  }

  sendAlert(message, type) {
    Swal.fire({
      icon: type,
      title: message,
      text: ''
    });
  }

  validateEmail() {
    if (!this.payloadForm.mailing_asunto.trim()) {
      this.sendAlert('Escriba el asunto del correo', 'error')
      return false
    }
    if (!this.payloadForm.message.trim()) {
      this.sendAlert('Escriba el mensaje del correo', 'error')
      return false
    }


    return true

  }


  async ngAfterViewInit() {
    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Email`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)

    var enlace = `${environment.url_api}LeadAPI/get_lead_detalle_by_id`;

    if (this.fuente == "Registro Navidad") {
       enlace = `${environment.url_api}NavidadAPI/get_lead_detalle_by_id`;
    } 


    const response = <any>await lastValueFrom(this.http.post(enlace, data))
    this.lead = response.lead

    if (!this.lead.disponible_sendmail) {
      if (this.fuente = "Lead") {
        const concesionario_id = this.lead.concesionario_id
        const lead_id = this.lead.id
        this.router.navigate([`leads/tienda/${concesionario_id}/${lead_id}`])
      }
      
    }
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('fuente', this.fuente)
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    data.append('comunicacion_type', `Email`)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_historial_comunicaciones_by_type`, data))
    if (response.success) {
      this.history = response.historial
    }
  }

  go_back() {

    var link = `/leads/tienda/${this.tienda_id}/${this.lead_id}/`;

    if (this.fuente == "Registro Navidad") {
      link = `campana-navidad/${this.lead_id}`;
    }  

    window.location.href = link;

  }

}

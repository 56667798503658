import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NavidadService {

  constructor(private http: HttpClient) { }

  async lead_service_init() {

    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}NavidadAPI/get_payload_init`, data))

    return response

  }

}

import { Component, OnInit, ViewChild } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  colors?: string[];
  title: ApexTitleSubtitle;

};

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;


  constructor() { 

    this.chartOptions = {
      title: {
        text: "Mi actividad en el sistema"
      },
      colors: ['#af4ed1', '#76C80F'], 
      series: [
        {
          name: "Días de conexión",
          data: [21, 25, 25, 20,  12]
        },
        {
          name: "Acciones",
          data: [ 204, 267 , 300 , 353, 129] 
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May"
    
        ]
      }
    };
  }    

  ngOnInit(): void {

    console.log('este es un bar chart')
  }

}

import { Input, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import * as he from 'he';


@Component({
  selector: 'app-modal-llamadas-telefonicas-detail',
  templateUrl: './modal-llamadas-telefonicas-detail.component.html',
  styleUrls: ['./modal-llamadas-telefonicas-detail.component.scss']
})
export class ModalLlamadasTelefonicasDetailComponent implements OnInit {

  @Input() private comunicacion_id
  @Input() private jwt

  audioUrl: string | any;


  comunicacion: any = {
    'attachment' : ''
  }

  constructor(
    private http: HttpClient
  ) { }


   normalizeText(text: string): string { 

    return he.decode(text);
   }





  
  async ngOnInit() {
    const data = new FormData()
    data.append('comunicacion_id', this.comunicacion_id)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_info_call_by_comunicacion_id`, data))
    if(response.success){
      this.comunicacion = response.comunicaciones; // Asegúrate de que response.comunicaciones tenga la estructura correcta

      
      /*
      const url = this.comunicacion.attachment; // Verifica que this.comunicacion.attachment sea la URL correcta del audio en Twilio
      const username = 'ACd57fb92b993fc9f6a00a0d260f8fd9e8'; // Verifica que estos sean los credenciales correctos para la autenticación básica
      const password = '97c6438c3bc1dc3cc412025cc14ea8cc';
      
      const headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa(username + ':' + password) });
      
      const sevice_audio = this.http.get(url, { headers, responseType: 'blob' });
      
      sevice_audio.subscribe(
        (blob: Blob) => {

          this.audioUrl = URL.createObjectURL(blob);

          console.log(this.audioUrl);

           },
        (error) => {
          console.error('Fetching audio failed:', error); 
         }
      );
      */
      


    }
  }






}

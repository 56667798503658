import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';

import * as moment from 'moment'
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs'

const Swal = require('sweetalert2')

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})


export class ReportesComponent implements OnInit, AfterViewInit {

  jwt: String
  rol: String
  user_type_id: Number
  usuario_nombre: String

  private routeSub: Subscription;

  filtros = {
    'fechas': {
      'fecha_inicio': '',
      'fecha_fin': '',
      'semana_format': '',
      'weekNumber': ''
    }
  }

  campus_length = 111

  data: any = {
    'leads_por_concesionario': {},
    'horas_de_actividad': {},
    'dias_de_conexion': {},
    'historial_de_acciones': [],
    'tiempos_promedios': {},
    'porcentajes': {},
    'leads_atendidos': {},
    'lead_por_status': [],
    'lead_por_fuente': []
  }

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute,) {
    this.jwt = JSON.parse(localStorage.getItem('user')).jwt
    this.usuario_nombre = JSON.parse(localStorage.getItem("user")).nombres
    this.user_type_id = JSON.parse(localStorage.getItem('user')).user_type_id

    const today = new Date();
    const firstDayOfCurrentYear = new Date(today.getFullYear(), 0, 1); // Primer día del año

    this.filtros.fechas.fecha_inicio = this.formatDate(firstDayOfCurrentYear);
    this.filtros.fechas.fecha_fin = this.formatDate(today);
    
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  }
  
  async ngOnInit() {
  }

  async make_filter() {

    const is_asesor = await this.checkIfIsAsesor()

    if (!is_asesor) {
      this.routeSub = this.route.params.subscribe(params => {
        this.rol = params.rol
      })
      this.ajustarFechas()
      const data = new FormData()
      data.append('jwt', this.jwt + "")
      data.append('user_type_id', JSON.parse(localStorage.user).user_type_id)
      data.append('filtros', JSON.stringify(this.filtros))


      Swal.showLoading()
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_dashboards`, data))
      Swal.close()
      if (response.success) {
        console.log('este es response', response)

        switch (JSON.parse(localStorage.getItem('user')).user_type_id) {
          case '8':


            this.data = {
              'leads_por_concesionario': response.data.leads_por_concesionario,
              'data_usuarios_horas_actividad': response.data.horas_de_actividad_usuario,
              'dias_de_conexion': response.data.dias_de_conexion,
              'historial_de_acciones': response.data.historial_de_acciones,
              'tiempos_promedios': response.data.tiempos_promedios,
              'porcentajes': response.data.porcentajes,
              'leads_atendidos': response.data.leads_atendidos,
              'lead_por_status': response.data.lead_por_status,
              'lead_por_fuente': response.data.lead_por_fuente,
            }



            break;

          case '2':

          var acciones_usuario = [
            {
                'ID' : 601550,
                'nombres' : 'Jeremías Smith',
                'created_time' : '2023-05-11 10:35:10',
                'accion' : '/Lead/get_form_to_edit_lead'
            },
            {
                'ID' : 601549,
                'nombres' : 'Jeremías Smith',
                'created_time' : '2023-05-11 10:34:40',
                'accion' : '/Lead/get_form_to_edit_lead'
            },
            {
                'ID' : 601548,
                'nombres' : 'Jeremías Smith',
                'created_time' : '2023-05-11 10:34:37',
                'accion' : '/Lead'
            },
            {
                'ID' : 601547,
                'nombres' : 'Jeremías Smith',
                'created_time' : '2023-05-11 10:34:32',
                'accion' : '/Lead/get_form_to_edit_lead'
            },
            {
                'ID' : 601546,
                'nombres' : 'Jeremías Smith',
                'created_time' : '2023-05-11 10:34:30',
                'accion' : '/Lead/get_form_to_edit_lead'
            }
        ]

          var tiempos_promedios =   {
                "tiempo_promedio_primera_apertura" : '3 horas',
                "tiempo_promedio_primera_edicion" : '4 horas',
                "tiempo_promedio_llamada" : '4 horas',
         
              }

              var porcentaje =   {
                "respondio_llamada" : '80',
                "chats_realizados" : '70',
                "cotizacion" : '60',
                "no_contactados" : '20',
         
              }

              var leads_atendidos =   {
                "atendidos" : '25',
                "porcentaje_atendidos" : '80'
         
              }

              var status = {
                'data': [
                  {
                    "name": "Frio",
                    "value": 5
                  },
                  {
                    "name": "Reprogramado",
                    "value": 2
                  },
                  {
                    "name": "Tibio",
                    "value": 5
                  },
                  {
                    "name": "Caliente",
                    "value": 5
                  },
                  {
                    "name": "Venta",
                    "value": 3
                  },
                  {
                    "name": "Pendiente",
                    "value": 5
                  }
                ],
                'cantidad': '25'
              };
              
              var fuente_data = [
                {
                  'name': 'Regular',
                  'value': '10'
                },
                {
                  'name': 'Tienda',
                  'value': '8'
                },
                {
                  'name': 'Facebook',
                  'value': '5'
                }
              ];
              
              var fuente = {
                'data': fuente_data,
                'cantidad': '25'
              };
            
              var dias_de_conexion_array = [
                {
                  "hora" : '08',
                  "dia" : 'Saturday',
                  "numero_dia" : '7',
                  "cantidad" : '49'
                },
                {
                  "hora" : '08',
                  "dia" : 'Friday',
                  "numero_dia" : '6',
                  "cantidad" : '122'
                },
                {
                  "hora" : '08',
                  "dia" : 'Thursday',
                  "numero_dia" : '5',
                  "cantidad" : '199'
                },
              ];

              var dias_de_conexion = {
                'cantidad_dias' : '30',
                'dias_de_conexion' : dias_de_conexion_array
              };
      

            this.data = {
              'leads_por_modelo': response.data.leads_por_modelo,
              'leads_por_concesionario': response.data.leads_por_concesionario,
              'tiempos_promedios': tiempos_promedios,
              'porcentajes': porcentaje,
              'acciones_usuario': acciones_usuario,
              'leads_atendidos':leads_atendidos,
              'lead_por_status': status,
              'lead_por_fuente': fuente,
              'dias_de_conexion': dias_de_conexion,
           
            }


            break;

          case '3':
            this.data = {
              'leads_por_concesionario': response.data.leads_por_concesionario,
              'data_usuarios_horas_actividad': response.data.horas_de_actividad_usuario,
              'dias_de_conexion': response.data.dias_de_conexion,
              'historial_de_acciones': response.data.historial_de_acciones,
              'tiempos_promedios': response.data.tiempos_promedios,
              'porcentajes': response.data.porcentajes,
              'leads_atendidos': response.data.leads_atendidos,
              'lead_por_status': response.data.lead_por_status,
              'lead_por_fuente': response.data.lead_por_fuente,
            }
            break;

            case '5':
              this.data = {
                'leads_por_concesionario': response.data.leads_por_concesionario,
                'data_usuarios_horas_actividad': response.data.horas_de_actividad_usuario,
                'dias_de_conexion': response.data.dias_de_conexion,
                'historial_de_acciones': response.data.historial_de_acciones,
                'tiempos_promedios': response.data.tiempos_promedios,
                'porcentajes': response.data.porcentajes,
                'leads_atendidos': response.data.leads_atendidos,
                'lead_por_status': response.data.lead_por_status,
                'lead_por_fuente': response.data.lead_por_fuente,
              }
              break;
        }


      }
    }
  }

  async checkIfIsAsesor() {
    if (JSON.parse(localStorage.user).user_type_id == 2) {
      if (localStorage.tienda_selected === undefined) {

        this.router.navigate(['/asesor-select-tienda']);
        return true
      }


    } else if (JSON.parse(localStorage.user).user_type_id == 3) {
      if (localStorage.tienda_selected === undefined) {
        this.router.navigate(['/jefe-select-tienda']);
        return true
      }
    }
  }


  obtenerSemana(fecha) {
    const date = moment(fecha); // crea un objeto Moment a partir de la fecha proporcionada
    const year = date.format('YYYY'); // extrae el año
    const week = date.week(); // extrae el número de semana

    return {
      'format': `${year}-W${week.toString().padStart(2, '0')}`,
      'weekNumber': week.toString().padStart(2, '0')
    }

  }


  async ngAfterViewInit() {
    this.ajustarFechas()
    this.make_filter()
  }

  ajustarFechas() {
    const res = this.obtenerSemana(this.filtros.fechas.fecha_inicio)
    this.filtros.fechas.semana_format = res.format
    this.filtros.fechas.weekNumber = res.weekNumber
  }




}

import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})


export class CampusService {

  private list_of_campus
  constructor(private http: HttpClient,
    public router: Router) {

  }

  muestraMensaje(mensaje: string) {
    alert(mensaje)
  }

  async get_list_of_campus() {
    if (!Array.isArray(this.list_of_campus)) {
      const data = new FormData()
      data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)

      if (JSON.parse(localStorage.user).user_type_id == 2) {
        data.append('tienda_id', localStorage.tienda_selected)
      }

      if (JSON.parse(localStorage.user).user_type_id == 3) {
        if (localStorage.tienda_selected) {
          data.append('tienda_id', localStorage.tienda_selected)
        } else {
          this.router.navigate(['/jefe-select-tienda'])
          return null
        }

      }

      data.append('user_type_id', JSON.parse(localStorage.user).user_type_id)
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_main_view`, data))
      console.log('este es el response de campus', response)

      if (response.list_of_campus.length == 0) {
        if (response.accion != 'redirect') {
          throw new Error("Usuario mal creado :c");
        } else {
          return response.url
        }
      }
      this.list_of_campus = response.list_of_campus
    }
    return this.list_of_campus
  }

  async get_list_of_campus_contact_ce() {
    if (!Array.isArray(this.list_of_campus)) {
      const data = new FormData()
      data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)

      if (JSON.parse(localStorage.user).user_type_id == 2) {
        data.append('tienda_id', localStorage.tienda_selected)
      }

      if (JSON.parse(localStorage.user).user_type_id == 3) {
        if (localStorage.tienda_selected) {
          data.append('tienda_id', localStorage.tienda_selected)
        } else {
          this.router.navigate(['/jefe-select-tienda'])
          return null
        }

      }

      data.append('user_type_id', JSON.parse(localStorage.user).user_type_id)
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_main_view`, data))
      console.log('este es el response de campus', response)

      if (response.list_of_campus.length == 0) {
        if (response.accion != 'redirect') {
          throw new Error("Usuario mal creado :c");
        } else {
          return response.url
        }
      }
      this.list_of_campus = response.list_of_campus
    }
    return this.list_of_campus
  }

  set_list_of_campus(list_of_campus) {
    this.list_of_campus = list_of_campus
  }




}

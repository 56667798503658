import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { lastValueFrom, Subscription } from 'rxjs'
import { HttpClient } from '@angular/common/http'
@Component({
  selector: 'app-dashboard-user-comparativa',
  templateUrl: './dashboard-user-comparativa.component.html',
  styleUrls: ['./dashboard-user-comparativa.component.scss']
})
export class DashboardUserComparativaComponent implements OnInit {

  jwt
  filtros = {
    'opciones_comparativas': 1,
    'opciones_universo': 1,
    'concesionario': '0',
    'seller': '0'
  }

  sellers = []

  concesionarios = [

  ]

  opciones_comparativas = [
    {
      id: 1,
      name: 'Cotizaciones enviadas'
    },
    {
      id: 2,
      name: 'Tiempo promedio de primera apertura'
    },
    {
      id: 3,
      name: 'Tiempo promedio de primera atención'
    },
    {
      id: 4,
      name: 'Cantidad de llamadas hechas'
    }
  ]

  opciones_universo = [
    {
      id: 1,
      name: 'Tienda'
    },
    {
      id: 2,
      name: 'Departamento'
    },
    {
      id: 3,
      name: 'Región'
    },
    {
      id: 4,
      name: 'Global'
    },
    // {
    //   id: 5,
    //   name: 'Vs'
    // },
  ]

  single: any[] = [
    {
      "name": "Germany",
      "value": 8940000
    },
    {
      "name": "USA",
      "value": 5000000
    },
    {
      "name": "France",
      "value": 7200000
    }
  ];

  view: any[] = null

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Vendedores';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Hola';

  colorScheme = {
    domain: ['#af4ed1', '#000']
  };

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute,) {
    this.jwt = JSON.parse(localStorage.getItem('user')).jwt
  }


  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt + "")
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_all_campus`, data))
    if (response.success) {
      this.concesionarios = response.data
      // this.filtros.concesionario = this.concesionarios[0].id
      this.filtros.concesionario = '3'
      this.get_sellers_by_tienda()
      this.make_graphic()
    }
  }

  async get_sellers_by_tienda() {
    this.filtros.concesionario
    const data = new FormData()
    data.append('jwt', this.jwt + "")
    data.append('campus_id', this.filtros.concesionario)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_all_sellers_by_conce_id`, data))
    if (response.success) {
      this.sellers = response.data
      this.filtros.seller = this.sellers[0].id
    }
  }

  async make_graphic() {
    const data = new FormData()
    data.append('jwt', this.jwt + "")
    data.append('filtros', JSON.stringify(this.filtros))
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_dashboard_comparativas`, data))
    console.log('response comparativa: ', response)
    if (response.success) {
      console.log(response.data)
      this.single = response.data.data
      this.xAxisLabel = response.data.criterio
      var colores = []

      response.data.data.forEach((el, index) => {
        if (index === 0) {
          colores.push('#af4ed1')
        } else {
          colores.push('#444444')
        }
      })

      this.colorScheme.domain = colores

    }
  }

  change_concesionario() {
    this.get_sellers_by_tienda()
    this.make_graphic()
  }
}

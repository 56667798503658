
    <app-breadcrumb [title]="'Registros Navidad'" [items]="['Registros Navidad']">
    </app-breadcrumb>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="container contenedor-leads">
                        <div class="row detalle-leads">
                            <h5 class="m-t-5" *ngIf="user_type_id == 3">Concesionario : {{campus_name}}</h5>
                        
                                <div class="col-12">
                                    <div class="card-body card-body-filtros">
                              
                                        <div id="collapseExample" >
                                            <div class="card mb-0">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <h5><u>Filtros</u></h5>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group row">
                                                                <label class="col-sm-3 col-form-label">Fecha de
                                                                    inicio</label>
                                                                <div class="col-sm-9">
                                                                    <input class="form-control digits"
                                                                        [(ngModel)]="filtros.fecha_inicio"
                                                                        id="example-datetime-local-input" type="date">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group row">
                                                                <label class="col-sm-3 col-form-label">Fecha de fin</label>
                                                                <div class="col-sm-9">
                                                                    <input class="form-control digits"
                                                                        [(ngModel)]="filtros.fecha_fin"
                                                                        id="example-datetime-local-input" type="date">
                                                                </div>
                                                            </div>
                                                        </div>
                                              
                                                
                                                        <div class="col-12">
                                                            <h5><u>Filtros UTMS</u></h5>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <div class="col-form-label">UTM Source</div>
                                                                <ng-select [items]="default_values.utm_source"
                                                                    class="js-example-basic-multiple" placeholder=""
                                                                    [(ngModel)]="filtros.utm_source" [multiple]="true">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <div class="col-form-label">UTM Medium</div>
                                                                <ng-select [items]="default_values.utm_medium"
                                                                    class="js-example-basic-multiple" placeholder=""
                                                                    [(ngModel)]="filtros.utm_medium" [multiple]="true">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <div class="col-form-label">UTM Campaign</div>
                                                                <ng-select [items]="default_values.utm_campaign"
                                                                    class="js-example-basic-multiple" placeholder=""
                                                                    [(ngModel)]="filtros.utm_campaign" [multiple]="true">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <div class="col-form-label">UTM Term/Content</div>
                                                                <ng-select [items]="default_values.utm_term"
                                                                    class="js-example-basic-multiple" placeholder=""
                                                                    [(ngModel)]="filtros.utm_term" [multiple]="true">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <h5><u>Filtros adicionales</u></h5>
                                                           
                                                            <div class="col-12 col-md-6">
                                                                <div class="form-group">
                                                                    <div class="form-group">
                                                                        <div class="col-form-label">Por texto</div>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="filtros.texto" placeholder="">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 div-button_filter">
                                                            <button id="make_filter" type="button" class="m-2 btn btn-primary"
                                                                (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                                                aria-controls="collapseExample"
                                                                [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                                                <span>{{buttonFiltrarTexto}}</span>
                                                            </button>

                                                            <button id="make_exportar" type="button" class="m-2 btn btn-success"
                                                                (click)="exportar()" >
                                                                <span>Exportar</span>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            <div class="col-12">
                                <div class="card-body custom-datatable noscroll table-card-container">
                                    <div class="custom-datatable table-responsive">
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                            class="row-border hover table table-leads pulpa-datatable-responsive nowrap display">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let cabecera of tableCabeceras" class="centered">{{cabecera.name}}</th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody *ngIf="persons?.length != 0">
                                                <tr *ngFor="let lead of persons" class="tr-mobile">
                                                   
                                                    <th *ngFor="let cabecera of tableCabeceras">

                                                        <div class="descripcion">{{cabecera.name}}</div>
                                                       
                                                        <container-element [ngSwitch]="cabecera.id">

                                                            <some-element class="mobile-detail" *ngSwitchCase="0">
                                                                {{lead.id}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="1">
                                                                {{lead.nombre}} {{lead.apellido}}         
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="2">
                                                                {{lead.dni}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="3">
                                                                {{lead.created_time}}
                                                            </some-element>
                                                
                                                            <some-element class="mobile-detail" *ngSwitchCase="4">
                                                                {{lead.categoria}}
                                                            </some-element>
                                                           
                                                            <some-element class="mobile-detail estado bg-{{formatearEstadoCliente(lead.estado_regalo)}}" *ngSwitchCase="5">
                                                                {{lead.estado_regalo}}
                                                            </some-element>

                                                            <some-element class="mobile-detail" *ngSwitchCase="6">
                                                                <a [routerLink]="[lead.id]"><i
                                                                        class="fa fa-arrow-circle-right lead-option"></i></a>
                                                            </some-element>
                                                        </container-element>
                                                    </th>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="persons?.length == 0">
                                                <tr>
                                                    <td colspan="3" class="no-data-available">{{message_to_show_table}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Input, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../../environments/environment';
import { Subscription, lastValueFrom } from 'rxjs';

const Swal = require('sweetalert2')
@Component({
  selector: 'app-send-cotizacion',
  templateUrl: './send-cotizacion.component.html',
  styleUrls: ['./send-cotizacion.component.scss']
})
export class SendCotizacionComponent implements OnInit {


  @Input() private lead_id
  @Input() private jwt
  @Input() private tienda_id
  @Input() private fuente

  protected lead

  files: File[] = [];
  files1: File[] = [];
  subject: any
  message: any

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal,
  ) {
    this.subject = ""
    this.message = ""
  }


  async canMessageSend() {
    const data = new FormData()
    data.append('fuente', this.fuente)
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Email`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)

    var enlace = `${environment.url_api}LeadAPI/get_lead_detalle_by_id`;

    if (this.fuente == "Registro Navidad") {
       enlace = `${environment.url_api}NavidadAPI/get_lead_detalle_by_id`;
    } 


    const response = <any>await lastValueFrom(this.http.post(enlace, data))

    if (response.success) {
      this.lead = response.lead

      if (!this.lead.disponible_sendmail) {
        Swal.fire('No se puede enviar un correo electrónico', '', 'warning')
        this.activeModalService.close({
          success: false
        })
      }
    }


  }



  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (this.files.length > 1) {
      this.replaceFile();
    }
  }

  replaceFile() {
    this.files.splice(0, 1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onSelect1(event) {
    this.files1.push(...event.addedFiles);
  }




  ngOnInit(): void {
    this.canMessageSend()
  }

  async onSubmit() {

    if (this.validate()) {
      const data = new FormData()

      data.append('file', this.files[0])
      data.append('subject', this.subject)
      data.append('message', this.message)
      data.append('fuente', this.fuente)

      data.append('jwt', this.jwt)
      data.append('lead_id', this.lead_id)

      Swal.showLoading()
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/enviar_cotizacion`, data))
      Swal.close()
      if (response.success) {
        Swal.fire(response.message, '', 'success')
        this.activeModalService.close({
          success: true
        })
      } else {
        Swal.fire(response.message, '', 'error')
      }
    }
  }

  validate(): boolean {
    if (!this.subject) {
      Swal.fire('Ingrese el asunto', '', 'error')
      return false
    }

    if (!this.message) {
      console.log(this.message)
      Swal.fire('Ingrese el mensaje', '', 'error')
      return false
    }


    if (!this.files[0]) {
      Swal.fire('¡Debe ingresar un archivo!', '', 'error')
      return false
    }

    return true
  }

  onCancel() {
    this.activeModalService.close({
      success: false
    });
  }

}

<app-breadcrumb [title]="'Inicio'" [items]="['Inicio']" [active_item]="''"></app-breadcrumb>

<div class="content">
    <div [ngSwitch]="user_type_id">

        <ng-template [ngSwitchCase]="'21'">
            <div class="col-12 grafico">
                <div class="card">
                    <div class="card-body">
                        <h5>Filtros</h5>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="fecha_inicio">Fecha de inicio</label>
                                    <input id="fecha_inicio" [(ngModel)]="filtros.fechas.fecha_inicio" type="date"
                                        class="form-control">
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="fecha_fin">Fecha de fin</label>
                                    <input id="fecha_fin" [(ngModel)]="filtros.fechas.fecha_fin" type="date"
                                        class="form-control">
                                </div>
                            </div>
                          

                            <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <button (click)="make_filter()" class="btn btn-primary">¡Filtrar!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-12">
                        <div class="card shadow-sm">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <h5 class="mb-0 text-primary"><i class="fas fa-dollar-sign me-2"></i> Registros Totales</h5>
                        </div>
    
            
    
                        <div class="card-body ">
                            <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                <th class="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Mostrar mensaje si no hay ventas -->
                                <ng-container *ngIf="data.registros_cantidad === 0; else salesData">
                                    <tr>
                                        <td class="text-center" colspan="2">Sin ventas</td>
                                    </tr>
                                    </ng-container>
                        
                                    <!-- Datos de ventas -->
                                    <ng-template #salesData>
                                    <tr >
                                        <td class="text-center">{{ data.registros_cantidad }}</td>
                                    </tr>
    
                                </ng-template>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
    
                    <div class="col-md-12">
                        <div class="card shadow-sm">
                            <div class="card-header d-flex align-items-center justify-content-between">
                                <h5 class="mb-0 text-primary"><i class="fas fa-dollar-sign me-2"></i> Categoría Registros </h5>
                            </div>
                            <div class="card-body">
                                <ngx-charts-pie-chart
                                  [view]="view"
                                  [scheme]="colorScheme"
                                  [results]="data.registros_por_status"
                                  [gradient]="gradient"
                                  [legend]="showLegend"
                                  [legendPosition]="legendPosition"
                                  [labels]="showLabels"
                                  [doughnut]="isDoughnut"
                                  (select)="onSelect($event)"
                                  (activate)="onActivate($event)"
                                  (deactivate)="onDeactivate($event)">
                                </ngx-charts-pie-chart>
                              </div>
                              
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="card shadow-sm">
                            <div class="card-header d-flex align-items-center justify-content-between">
                                <h5 class="mb-0 text-primary"><i class="fas fa-dollar-sign me-2"></i> Edades Registros </h5>
                            </div>
                            <div class="card-body">
                                <ngx-charts-pie-chart
                                  [view]="view"
                                  [scheme]="colorScheme"
                                  [results]="data.registros_por_edad"
                                  [gradient]="gradient"
                                  [legend]="showLegend"
                                  [legendPosition]="legendPosition"
                                  [labels]="showLabels"
                                  [doughnut]="isDoughnut"
                                  (select)="onSelect($event)"
                                  (activate)="onActivate($event)"
                                  (deactivate)="onDeactivate($event)">
                                </ngx-charts-pie-chart>
                              </div>
                              
                        </div>
                    </div>

                </div>
                

               
            </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'8'">
            <div class="col-12 grafico">
                <div class="card">
                    <div class="card-body">
                        <h5>Filtros</h5>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="fecha_inicio">Fecha de inicio</label>
                                    <input id="fecha_inicio" [(ngModel)]="filtros.fechas.fecha_inicio" type="date"
                                        class="form-control">
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="fecha_fin">Fecha de fin</label>
                                    <input id="fecha_fin" [(ngModel)]="filtros.fechas.fecha_fin" type="date"
                                        class="form-control">
                                </div>
                            </div>
                          

                            <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <button (click)="make_filter()" class="btn btn-primary">¡Filtrar!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="row dashboards-container">

                        <div class="col-12">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-user-comparativa></app-dashboard-user-comparativa>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-leads-por-concesionario [data]="data.leads_por_concesionario">
                                        </app-dashboard-leads-por-concesionario>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12 m-t-30">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuarios-hora-actividad [semana]="filtros.fechas.semana_format"
                                            [heatMapData]="data.data_usuarios_horas_actividad">
                                        </app-dashboard-usuarios-hora-actividad>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-12 m-t-30">
                            <div class="card-body">
                                <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                    <app-dashboard-historial-usuario-actividades [data]="data.historial_de_acciones">
                                    </app-dashboard-historial-usuario-actividades>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 m-t-30">
                            <div class="card-body">
                                <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                    <app-dashboard-usuario-tiempo-promedio [data]="data.tiempos_promedios">
                                    </app-dashboard-usuario-tiempo-promedio>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 m-t-30">
                            <div class="card-body">
                                <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                    <app-dashboard-usuario-porcentajes [data]="data.porcentajes">
                                    </app-dashboard-usuario-porcentajes>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 m-t-30">
                            <div class="card-body">
                                <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                    <app-dashboard-usuario-cantidades-lead [data]="data.leads_atendidos">
                                    </app-dashboard-usuario-cantidades-lead>
                                </div>
                            </div>
                        </div>



                        <div class="col-12 m-t-30">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-cantidad-lead-por-status [data]="data.lead_por_status.data"
                                            [designatedTotal]="data.lead_por_status.cantidad">
                                        </app-dashboard-cantidad-lead-por-status>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 m-t-30">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-cantidad-lead-por-fuente [single]="data.lead_por_fuente.data">
                                        </app-dashboard-cantidad-lead-por-fuente>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'5'">
                <div class="col-12 grafico">
                    <div class="card">
                        <div class="card-body">
                            <h5>Filtros</h5>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="fecha_inicio">Fecha de inicio</label>
                                        <input id="fecha_inicio" [(ngModel)]="filtros.fechas.fecha_inicio" type="date"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="fecha_fin">Fecha de fin</label>
                                        <input id="fecha_fin" [(ngModel)]="filtros.fechas.fecha_fin" type="date"
                                            class="form-control">
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="concesionarios">Seleccione Parque</label>
                                        <button class="btn btn-secondary d-block">Concesionarios
                                            <b>({{campus_length}})</b></button>
                                    </div>
                                </div> -->

                                <div class="col-12 col-sm-12">
                                    <div class="form-group">
                                        <button (click)="make_filter()" class="btn btn-primary">¡Filtrar!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="row dashboards-container">

                            <!-- <div class="col-12">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-user-comparativa></app-dashboard-user-comparativa>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-12">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-leads-por-concesionario [data]="data.leads_por_concesionario">
                                            </app-dashboard-leads-por-concesionario>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-usuarios-hora-actividad [semana]="filtros.fechas.semana_format"
                                                [heatMapData]="data.data_usuarios_horas_actividad">
                                            </app-dashboard-usuarios-hora-actividad>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-usuarios-dias-conexion
                                                [single]="data.dias_de_conexion.dias_de_conexion"
                                                [designatedTotal]="data.dias_de_conexion.cantidad_dias">
                                            </app-dashboard-usuarios-dias-conexion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-historial-usuario-actividades [data]="data.historial_de_acciones">
                                        </app-dashboard-historial-usuario-actividades>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuario-tiempo-promedio [data]="data.tiempos_promedios">
                                        </app-dashboard-usuario-tiempo-promedio>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuario-porcentajes [data]="data.porcentajes">
                                        </app-dashboard-usuario-porcentajes>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuario-cantidades-lead [data]="data.leads_atendidos">
                                        </app-dashboard-usuario-cantidades-lead>
                                    </div>
                                </div>
                            </div>



                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-cantidad-lead-por-status [data]="data.lead_por_status.data"
                                                [designatedTotal]="data.lead_por_status.cantidad">
                                            </app-dashboard-cantidad-lead-por-status>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-cantidad-lead-por-fuente [single]="data.lead_por_fuente.data">
                                            </app-dashboard-cantidad-lead-por-fuente>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'3'">
                <div class="col-12 grafico">
                    <div class="card">
                        <div class="card-body">
                            <h5>Filtros</h5>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="fecha_inicio">Fecha de inicio</label>
                                        <input id="fecha_inicio" [(ngModel)]="filtros.fechas.fecha_inicio" type="date"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="fecha_fin">Fecha de fin</label>
                                        <input id="fecha_fin" [(ngModel)]="filtros.fechas.fecha_fin" type="date"
                                            class="form-control">
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="concesionarios">Seleccione Parque</label>
                                        <button class="btn btn-secondary d-block">Concesionarios
                                            <b>({{campus_length}})</b></button>
                                    </div>
                                </div> -->

                                <div class="col-12 col-sm-12">
                                    <div class="form-group">
                                        <button (click)="make_filter()" class="btn btn-primary">¡Filtrar!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="row dashboards-container">

                            <!-- <div class="col-12">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-user-comparativa></app-dashboard-user-comparativa>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-12">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-leads-por-concesionario [data]="data.leads_por_concesionario">
                                            </app-dashboard-leads-por-concesionario>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-usuarios-hora-actividad [semana]="filtros.fechas.semana_format"
                                                [heatMapData]="data.data_usuarios_horas_actividad">
                                            </app-dashboard-usuarios-hora-actividad>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-usuarios-dias-conexion
                                                [single]="data.dias_de_conexion.dias_de_conexion"
                                                [designatedTotal]="data.dias_de_conexion.cantidad_dias">
                                            </app-dashboard-usuarios-dias-conexion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-historial-usuario-actividades [data]="data.historial_de_acciones">
                                        </app-dashboard-historial-usuario-actividades>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuario-tiempo-promedio [data]="data.tiempos_promedios">
                                        </app-dashboard-usuario-tiempo-promedio>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuario-porcentajes [data]="data.porcentajes">
                                        </app-dashboard-usuario-porcentajes>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuario-cantidades-lead [data]="data.leads_atendidos">
                                        </app-dashboard-usuario-cantidades-lead>
                                    </div>
                                </div>
                            </div>



                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-cantidad-lead-por-status [data]="data.lead_por_status.data"
                                                [designatedTotal]="data.lead_por_status.cantidad">
                                            </app-dashboard-cantidad-lead-por-status>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <div class="flot-chart-container">
                                        <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                            <app-dashboard-cantidad-lead-por-fuente [single]="data.lead_por_fuente.data">
                                            </app-dashboard-cantidad-lead-por-fuente>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </ng-template>

        

        <ng-template [ngSwitchCase]="'2'">

            <div class="col-12 grafico">
                <div class="card">
                    <div class="row dashboards-container">
                        <div class="col-12">

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                  <app-combo-chart></app-combo-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 grafico">
                <div class="card">
                    <div class="row dashboards-container">
                        <div class="col-12">

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <app-bar-chart></app-bar-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 grafico">
                <div class="card">
                    <div class="row dashboards-container">
                        <div class="col-12">

                            <div class="col-12 m-t-30">
                                <div class="card-body">
                                    <app-calendar-chart></app-calendar-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 grafico">
                <div class="card">
                    <div class="row dashboards-container">
                        <div class="col-12"> 
                
    
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-historial-usuario-actividades [data]="data.acciones_usuario"></app-dashboard-historial-usuario-actividades>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template [ngSwitchCase]="'2'">
            <app-dashboard-usuario-tiempo-promedio [data]="data.tiempos_promedios" ></app-dashboard-usuario-tiempo-promedio>
        </ng-template>


        <ng-template [ngSwitchCase]="'2'">
            <div class="col-12 grafico">
                <div class="card">
                    <div class="row dashboards-container">
                        <div class="col-12">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-usuario-porcentajes [data]="data.porcentajes"></app-dashboard-usuario-porcentajes>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        




        <ng-template [ngSwitchCase]="'2'">
            <div class="col-12 grafico">
                <div class="card">
                    <div class="row dashboards-container">
                        <div class="col-12">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">

                                        <app-dashboard-usuario-cantidades-lead [data]="data.leads_atendidos">
                                        </app-dashboard-usuario-cantidades-lead>

                                        <app-dashboard-cantidad-lead-por-status [data]="data.lead_por_status.data">
                                        </app-dashboard-cantidad-lead-por-status>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>


        <ng-template [ngSwitchCase]="'2'">
            <div class="col-12 grafico">
                <div class="card">
                    <div class="row dashboards-container">
                        <div class="col-12">
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction chart-pulpa">
                                        <app-dashboard-cantidad-lead-por-fuente [single]="data.lead_por_fuente.data">
                                        </app-dashboard-cantidad-lead-por-fuente>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>


</div>
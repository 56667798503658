<!-- <h3>Historial de acciones</h3>
<table class="table">
    <thead>
        <tr>
            <th>Usuario</th>
            <th>Acción</th>
            <th>Hora</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let d of data">
            <td>{{d.nombres}}</td>
            <td>{{d.accion}}</td>
            <td>{{d.created_time}}</td>
        </tr>
    </tbody>
</table>

-->

<h3>Historial de acciones</h3>
<table class="table">
    <thead>
        <tr>
            <th>Usuario</th>
            <th>Acción</th>
            <th>Hora</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Jeremías Smith</td>
            <td>Inició sesión</td>
            <td>2024-07-11 08:15</td>
        </tr>
        <tr>
            <td>Jeremías Smith</td>
            <td>Agregó nuevo cliente</td>
            <td>2024-07-11 09:00</td>
        </tr>
        <tr>
            <td>Jeremías Smith</td>
            <td>Actualizó información de producto</td>
            <td>2024-07-11 10:30</td>
        </tr>
    
        <tr>
            <td>Jeremías Smith</td>
            <td>Inició sesión</td>
            <td>2024-07-12 08:20</td>
        </tr>
        <tr>
            <td>Jeremías Smith</td>
            <td>Realizó llamada a cliente</td>
            <td>2024-07-12 09:10</td>
        </tr>
        <tr>
            <td>Jeremías Smith</td>
            <td>Registró nueva venta</td>
            <td>2024-07-12 10:25</td>
        </tr>
        <tr>
            <td>Jeremías Smith</td>
            <td>Envió cotización</td>
            <td>2024-07-12 11:40</td>
        </tr>
  
    </tbody>
</table>

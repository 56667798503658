import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../environments/environment';
const Swal = require('sweetalert2')

@Injectable({
    providedIn: "root"
})
export class UsersService {
    public showLoader: boolean = false;
    public userData;

    public roles = []


    constructor(private http: HttpClient, public router: Router) {
        this.roles[1] = 'Administrador de sistema'
        this.roles[2] = 'Vendedor'
        this.roles[3] = 'Jefe de tienda'
        this.roles[5] = 'Supervisor de tiendas'
        this.roles[7] = 'Social Media'
        this.roles[8] = 'Administrador'
        this.roles[9] = 'Escuela de Manejo'
        this.roles[10] = 'Postventa'
        this.roles[11] = 'Recall'
        this.roles[12] = 'Administrador Recall'
        this.roles[13] = 'Recall - Callcenter'
        this.roles[14] = 'Taller'
        this.roles[15] = 'Supervisor Contact Center'
        this.roles[16] = 'Jefe de tienda Post Venta'
        this.roles[20] = 'Supervisor Contact Center Post Venta'
    }

    async multi_rol(roles) {
        console.log('multi_rol')
        const rol = await Swal.fire({
            title: 'Seleccione su rol',
            input: 'select',
            inputOptions: {
                'Roles': roles
            },
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    return value
                })
            }
        })

        console.log(`multi_rol : ${rol}`)
    }

    async entrar(response, rol_id, multi_rol) {
        Swal.close()
        response.data.user_type_id = rol_id
        this.userData = response.data
        this.userData.user_type = this.roles[this.userData.user_type_id]
        this.userData.multi_rol = false
        if (multi_rol) {
            this.userData.multi_rol = true
        }

        localStorage.setItem('user', JSON.stringify(this.userData));
        if ([2, 3, 5].includes(Number.parseInt(JSON.parse(localStorage.user).user_type_id))) {
            const data = new FormData()
            data.append('jwt', JSON.parse(localStorage.user).jwt)
            const response2 = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_tiendas_asigned`, data))
            console.log('responseeeeeee', response2)
            if (response2.success) {
                if (response2.tiendas.length == 1) {
                    localStorage.setItem('tienda_selected', response2.tiendas[0].id)
                    this.router.navigate(['/'])
                } else {
                    this.router.navigate(['/asesor-select-tienda'])
                }
            } else {
                console.log(response.message)
            }
        } else {
            this.router.navigate(['/dashboard/default']);
        }
    }


    async ingresar(response) {
        if (response.multirol) {
            await Swal.fire({
                title: 'Seleccione su rol',
                input: 'select',
                inputOptions: {
                    'Roles': response.multirol
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        this.entrar(response, value, true)
                    })
                }
            })
        } else {
            this.entrar(response, response.data.user_type_id, false)
        }


    }

    async login(email: string, password: string) {
        this.showLoader = true;

        const data = new FormData;
        data.append('email', email)
        data.append('password', password)
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/make_login`, data))

        if (response.success) {
            this.ingresar(response)
        } else {
            this.showLoader = false
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.message,
            });
        }
        this.showLoader = false
    }


    SetUserData(user: any) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
    }
}